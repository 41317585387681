@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.termsModalContent {
  padding-left: 24px;
  padding-right: 24px;
  & .sectionContent {
    width: 100%;
    margin: 0 auto;
    @media (--viewportMedium) {
      max-width: 60%;
    }
    & > header {
      justify-content: flex-start;
      padding: 0;
      margin: 0 0 30px 0;
      width: 100%;
      max-width: 100%;
    }
    & > div {
      padding: 0;
      width: 100%;
      max-width: 100%;
      & h1 {
        font-size: 26px;
      }
      & h2 {
        font-size: 20px;
      }
      & p {
        margin: 10px 0;
        max-width: 100%;
      }
    }
  }
}
.headerToppadding {
  padding-top: 80px;
}
