.link {
  color: var(--marketplaceColor);
  width: 40px;
  height: 40px;
  border-radius: 100px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffeef1;
  transition: var(--transitionStyle);
  &:hover {
    background-color: var(--marketplaceColor);
    transition: var(--transitionStyle);

    & > svg {
      & path {
        fill: var(--colorWhite);
      }
    }
  }
  & > svg {
    fill: var(--marketplaceColor);
  }
}
