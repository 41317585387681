@import '../../../../styles/customMediaQueries.css';

.root {
  /* margin-top: 50px; */
  border-top: 1px solid var(--colorGrey100);
  position: relative;
  width: 100%;
  @media (--viewportMedium) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  & > div {
    padding: 0;
  }
}
.copyrightSec {
  background-color: #f9f9f9;
  & .copyright {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8c8385;
    padding: 20px 20px;
    display: inline-block;
    text-align: center;
    width: 100%;
  }
}
.footer {
  width: 100%;
  margin: 0 auto;
  padding: 24px;

  @media (--viewportLarge) {
    max-width: 90%;
  }
  @media (--viewportLarge) {
    padding: 50px 32px 24px;
  }
}

.footer {
  & ul {
    list-style-type: disc;
    padding-inline-start: 0px;
    margin-top: 1px;
    padding-left: 20px;
    & li {
      list-style-position: outside;
      margin-bottom: 10px;
      line-height: 100%;

      &:last-child {
        margin-bottom: 0;
      }
      &::marker {
        color: #8c8385;
        width: 8px;
        height: 8px;
      }
      & a {
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #8c8385;
        display: inline-block;
      }
    }
  }
}
.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.content {
  display: grid;
}

.grid {
  grid-area: grid;
  display: grid;
  justify-content: center;
  align-content: start;
  grid-auto-rows: auto;
  grid-gap: 32px;
}

.gridCol1,
.gridCol2,
.gridCol3,
.gridCol4 {
  grid-template-columns: 1fr;
}

.detailsInfo {
  grid-area: links;
}

.slogan {
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: #8c8385;
  @media (--viewportLarge) {
    font-size: 16px;
  }
}
.sloganMobile {
  padding-bottom: 24px;

  @media (--viewportLarge) {
    display: none;
  }
}
.sloganDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.logo {
  grid-area: logo;
}

.icons {
  margin-top: 24px;

  & > * {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}
.logoImage {
  height: 24px;
  width: auto;
  max-width: 188px;
  object-fit: contain;
  object-position: center;
}

@media (min-width: 350px) {
  .gridCol2,
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 600px) {
  .gridCol3,
  .gridCol4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (--viewportMedium) {
  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (--viewportLarge) {
  .detailsInfo {
    grid-area: details;
  }

  .contentCol1 {
    /* 1/6 of space is for grid, and grid uses 1 column internally */
    grid-template-columns: 2fr 3fr 1fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }
  .contentCol2 {
    /* 2/6 of space is for grid, and grid splits it to 2 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details . grid'
      'details . grid'
      'details . grid';
  }
  .contentCol3 {
    /* 3/6 of space is for grid, and grid splits it to 3 columns */
    grid-template-columns: 2fr 1fr 1fr 2fr;
    grid-template-areas:
      'logo . . .'
      'details . grid grid'
      'details . grid grid'
      'details . grid grid';
  }
  .contentCol4 {
    /* 4/6 of space is for grid, and grid splits it to 4 columns */
    grid-template-columns: 2fr 2fr 2fr;
    grid-template-areas:
      'logo . .'
      'details grid grid'
      'details grid grid'
      'details grid grid';
  }

  .gridCol1 {
    grid-template-columns: 1fr;
  }

  .gridCol2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .gridCol3 {
    grid-template-columns: repeat(3, 1fr);
  }

  .gridCol4 {
    grid-template-columns: repeat(4, 1fr);
  }
}
.footerLogo {
  margin-bottom: 10px;
  & .logoLink {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    @media (--viewportLargeWithPaddings) {
      font-size: 20px;
      line-height: 30px;
    }
  }
}
