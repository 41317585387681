@import '../../../../styles/customMediaQueries.css';

.root {
  display: inline-block;
}

.link {
  &:hover {
    color: var(--colorGrey700);
  }
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}
